import React, { useState, useEffect } from 'react';
import Chart from 'chart.js/auto';

const TopSenderDomains = () => {
  const [companyID, setCompanyID] = useState('');
  const [domainData, setDomainData] = useState([]);

  const fetchTopSenderDomains = async () => {
    try {
      const response = await fetch('/api/top-sender-domains', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ companyID }),
      });
      const data = await response.json();
      setDomainData(data);
    } catch (error) {
      console.error('Error fetching sender domain data:', error);
    }
  };

  useEffect(() => {
    if (domainData.length > 0) {
      const ctx = document.getElementById('domainChart').getContext('2d');
      new Chart(ctx, {
        type: 'bar',
        data: {
          labels: domainData.map((item) => item.senderDomain),
          datasets: [
            {
              label: 'Email Count',
              data: domainData.map((item) => item.email_count),
              backgroundColor: 'rgba(75, 192, 192, 0.2)',
              borderColor: 'rgba(75, 192, 192, 1)',
              borderWidth: 1,
            },
          ],
        },
        options: { scales: { y: { beginAtZero: true } } },
      });
    }
  }, [domainData]);

  return (
    <div>
      <h1>Top 10 Sender Domains by Email Count</h1>
      <div className="company-id-input"><input
        type="text"
        value={companyID}
        onChange={(e) => setCompanyID(e.target.value)}
        placeholder="Enter Company ID"
      /></div>
      <div className="report-buttons"><button onClick={fetchTopSenderDomains}>Fetch Data</button></div>
      <canvas id="domainChart"></canvas>
    </div>
  );
};

export default TopSenderDomains;
