import React, { useState, useEffect } from 'react';
import Chart from 'chart.js/auto';

const ClassificationSummary = () => {
  const [companyID, setCompanyID] = useState('');
  const [classificationData, setClassificationData] = useState([]);

  const fetchClassificationSummary = async () => {
    try {
      const response = await fetch('/api/classification-summary', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ companyID }),
      });
      const data = await response.json();
      setClassificationData(data);
    } catch (error) {
      console.error('Error fetching classification summary:', error);
    }
  };

  useEffect(() => {
    if (classificationData.length > 0) {
      const ctx = document.getElementById('classificationChart').getContext('2d');
      new Chart(ctx, {
        type: 'bar',
        data: {
          labels: classificationData.map((item) => item.classification),
          datasets: [
            {
              label: 'Email Count',
              data: classificationData.map((item) => item.email_count),
              backgroundColor: 'rgba(75, 192, 192, 0.2)',
              borderColor: 'rgba(75, 192, 192, 1)',
              borderWidth: 1,
            },
          ],
        },
        options: { scales: { y: { beginAtZero: true } } },
      });
    }
  }, [classificationData]);

  return (
    <div>
      <h1>Classification Summary</h1>
      <div className="company-id-input"><input
        type="text"
        value={companyID}
        onChange={(e) => setCompanyID(e.target.value)}
        placeholder="Enter Company ID"
      /></div>
      <div className="report-buttons"><button onClick={fetchClassificationSummary}>Fetch Data</button></div>
      <canvas id="classificationChart"></canvas>
    </div>
  );
};

export default ClassificationSummary;
