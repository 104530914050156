import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Dashboard from './components/Dashboard';
import SenderDomainAnalysis from './components/SenderDomainAnalysis';
import ClassificationSummary from './components/ClassificationSummary';
import ClassificationCertainty from './components/ClassificationCertainty';
import TopSenderDomains from './components/TopSenderDomains';
import TopPhishingDomains from './components/TopPhishingDomains';
import './styles.css';

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Dashboard} />
        <Route path="/sender-domain-analysis" component={SenderDomainAnalysis} />
        <Route path="/top-phishing-domains" component={TopPhishingDomains} />
        <Route path="/classification-summary" component={ClassificationSummary} />
        <Route path="/classification-certainty" component={ClassificationCertainty} />
        <Route path="/top-sender-domains" component={TopSenderDomains} />
      </Switch>
    </Router>
  );
}

export default App;
