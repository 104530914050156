import React, { useState, useEffect } from 'react';
import Chart from 'chart.js/auto';

const TopPhishingDomains = () => {
  const [companyID, setCompanyID] = useState('');
  const [phishingData, setPhishingData] = useState([]);

  const fetchTopPhishingDomains = async () => {
    try {
      const response = await fetch('/api/top-phishing-domains', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ companyID }),
      });
      const data = await response.json();
      setPhishingData(data);
    } catch (error) {
      console.error('Error fetching phishing domain data:', error);
    }
  };

  useEffect(() => {
    if (phishingData.length > 0) {
      const ctx = document.getElementById('phishingChart').getContext('2d');
      new Chart(ctx, {
        type: 'bar',
        data: {
          labels: phishingData.map((item) => item.senderDomain),
          datasets: [
            {
              label: 'Phishing Count',
              data: phishingData.map((item) => item.phishing_count),
              backgroundColor: 'rgba(255, 99, 132, 0.2)',
              borderColor: 'rgba(255, 99, 132, 1)',
              borderWidth: 1,
            },
          ],
        },
        options: { scales: { y: { beginAtZero: true } } },
      });
    }
  }, [phishingData]);

  return (
    <div>
      <h1>Top 10 Phishing Sender Domains</h1>
      <div className="company-id-input"><input
        type="text"
        value={companyID}
        onChange={(e) => setCompanyID(e.target.value)}
        placeholder="Enter Company ID"
      /></div>
      <div className="report-buttons"><button onClick={fetchTopPhishingDomains}>Fetch Data</button></div>
      <canvas id="phishingChart"></canvas>
    </div>
  );
};

export default TopPhishingDomains;
