import React, { useState, useEffect } from 'react';
import Chart from 'chart.js/auto';

const SenderDomainAnalysis = () => {
  const [companyID, setCompanyID] = useState('');
  const [emailData, setEmailData] = useState([]);
  
  const fetchSenderDomainData = async () => {
    try {
      const response = await fetch('/api/sender-domain-analysis', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ companyID }),
      });
      const data = await response.json();
      setEmailData(data);
    } catch (error) {
      console.error('Error fetching sender domain analysis:', error);
    }
  };

  useEffect(() => {
    if (emailData.length > 0) {
      const ctx = document.getElementById('domainChart').getContext('2d');
      new Chart(ctx, {
        type: 'bar',
        data: {
          labels: emailData.map((item) => item.senderDomain),
          datasets: [{
            label: 'Email Count',
            data: emailData.map((item) => item.email_count),
            backgroundColor: 'rgba(75, 192, 192, 0.2)',
            borderColor: 'rgba(75, 192, 192, 1)',
            borderWidth: 1,
          }],
        },
        options: { scales: { y: { beginAtZero: true } } },
      });
    }
  }, [emailData]);

  return (
    <div>
      <h1>Sender Domain Analysis</h1>
      <div className="company-id-input"><input
        type="text"
        value={companyID}
        onChange={(e) => setCompanyID(e.target.value)}
        placeholder="Enter Company ID"
      /></div>
      <div className="report-buttons">
      <button onClick={fetchSenderDomainData}>Fetch Data</button></div>
      <canvas id="domainChart"></canvas>
    </div>
  );
};

export default SenderDomainAnalysis;
