import React, { useState, useEffect } from 'react';
import Chart from 'chart.js/auto';

const ClassificationCertaintyAnalysis = () => {
  const [companyID, setCompanyID] = useState('');
  const [certaintyData, setCertaintyData] = useState([]);

  const fetchCertaintyData = async () => {
    try {
      const response = await fetch('/api/classification-certainty', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ companyID }),
      });
      const data = await response.json();
      setCertaintyData(data);
    } catch (error) {
      console.error('Error fetching classification certainty data:', error);
    }
  };

  useEffect(() => {
    if (certaintyData.length > 0) {
      const ctx = document.getElementById('certaintyChart').getContext('2d');
      new Chart(ctx, {
        type: 'bar',
        data: {
          labels: certaintyData.map((item) => item.classification),
          datasets: [
            {
              label: 'Avg Certainty',
              data: certaintyData.map((item) => item.avg_certainty),
              backgroundColor: 'rgba(75, 192, 192, 0.2)',
              borderColor: 'rgba(75, 192, 192, 1)',
              borderWidth: 1,
            },
            {
              label: 'Min Certainty',
              data: certaintyData.map((item) => item.min_certainty),
              backgroundColor: 'rgba(255, 206, 86, 0.2)',
              borderColor: 'rgba(255, 206, 86, 1)',
              borderWidth: 1,
            },
            {
              label: 'Max Certainty',
              data: certaintyData.map((item) => item.max_certainty),
              backgroundColor: 'rgba(153, 102, 255, 0.2)',
              borderColor: 'rgba(153, 102, 255, 1)',
              borderWidth: 1,
            },
          ],
        },
        options: { scales: { y: { beginAtZero: true } } },
      });
    }
  }, [certaintyData]);

  return (
    <div>
      <h1>Classification Certainty Analysis</h1>
      <div className="company-id-input"><input
        type="text"
        value={companyID}
        onChange={(e) => setCompanyID(e.target.value)}
        placeholder="Enter Company ID"
      /></div>
      <div className="report-buttons"><button onClick={fetchCertaintyData}>Fetch Data</button></div>
      <canvas id="certaintyChart"></canvas>
    </div>
  );
};

export default ClassificationCertaintyAnalysis;
