import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

const Dashboard = () => {
  const [companyID, setCompanyID] = useState('');
  const history = useHistory();

  const navigateTo = (path) => {
    history.push({
      pathname: path,
      state: { companyID }
    });
  };

  return (
    <div>
      <h1>Company Report Dashboard</h1>
      <section id="report-selection">
        <h2>Select a Report</h2>
        <div className="report-buttons">
          <button onClick={() => navigateTo('/sender-domain-analysis')}>Sender Domain Analysis</button>
          <button onClick={() => navigateTo('/top-phishing-domains')}>Top Phishing Domains</button>
          <button onClick={() => navigateTo('/classification-summary')}>Classification Summary</button>
          <button onClick={() => navigateTo('/classification-certainty')}>Classification Certainty</button>
          <button onClick={() => navigateTo('/top-sender-domains')}>Top Sender Domains</button>
        </div>
      </section>
    </div>
  );
};

export default Dashboard;
